import React, { useState } from "react";
import {
    Button,
	Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    useColorModeValue,
    useToast,
    IconButton,
    Radio,
    RadioGroup,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import AuthApi from "../../api/auth";
import { useAuth } from "../../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SignUp() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        birthdate: '',
        gender: '',
		acceptCGU: false,
    });
    const [error, setError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const history = useHistory();
    const auth = useAuth();
    const user = auth ? auth.user : null;

    const toast = useToast();

    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("black", "gray.600");
    const bgColorButton = useColorModeValue("black", "gray.800");
	const textColorButton = useColorModeValue("white", "gray.200");
	const inputColor = useColorModeValue("gray.700", "#94aca4");

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const validateUsername = (username) => {
        const regex = /^[a-zA-Z0-9]{3,15}$/;
        return regex.test(username);
    };

    const validateEmail = (email) => {
        const regex = /^(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._+-]+(?<![_.-])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

	const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Handle checkbox state
        if (type === "checkbox") {
            setFormData(prev => ({
                ...prev,
                [name]: checked
            }));
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        if (name === "password") {
            if (!validatePassword(value)) {
                setPasswordError(t("register.passwordError"));
            } else {
                setPasswordError('');
                if (formData.confirmPassword && value !== formData.confirmPassword) {
                    setConfirmPasswordError(t("register.confirmPasswordError"));
                } else {
                    setConfirmPasswordError('');
                }
            }
        }

        if (name === "confirmPassword") {
            if (value !== formData.password) {
                setConfirmPasswordError(t("register.confirmPasswordError"));
            } else {
                setConfirmPasswordError('');
            }
        }

        if (name === "username") {
            if (!validateUsername(value)) {
                setUsernameError(t("register.usernameError"));
            } else {
                setUsernameError('');
            }
        }

        if (name === "email") {
            if (!validateEmail(value)) {
                setEmailError(t("register.emailError"));
            } else {
                setEmailError('');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user && user.token) {
            return; // Already signed in
        }
        if (passwordError || confirmPasswordError || usernameError || emailError) {
            setError(t("register.correctErrors"));
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            setError(t("register.passwordMismatch"));
            return;
        }
        if (!formData.acceptCGU) { // Check if CGU is accepted
            setError(t("register.acceptCGUError"));
            return;
        }
        try {
            const response = await AuthApi.Register(formData);
            if (response.data.success) {
                toast({
                    title: t("register.accountCreated"),
                    description: t("register.confirmEmail"),
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                history.push("/admin/signin");
            } else {
                setError(response.data.msg);
            }
        } catch (error) {
            setError(error.response?.data.msg || t("register.error"));
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <form onSubmit={handleSubmit}>
            {user && user.token ? (
                <Text fontSize="18px" color={textColor} fontWeight="bold" textAlign="center" mb="22px">
                    {t("register.alreadySignedIn")}
                </Text>
            ) : (
                <Flex
                    direction="column"
                    w={{ sm: '95%', md: '80%', xl: '60%' }}
                    borderRadius="15px"
                    borderColor={borderColor}
                    borderWidth="2px"
                    borderStyle="solid"
                    p="40px"
					boxShadow="0px 4px 9px rgba(0, 0, 0, 0.09)"
                    bg={bgColor}
                    mx="auto"
                >
                    <Text fontSize="24px" color={textColor} fontWeight="bold" textAlign="left">
                        {t("register.createAccount")}
                    </Text>
                    <Text fontSize="xl" color={textColor} textAlign="left" mb="30px">
                        Oncopilote.io
                    </Text>
                    <FormControl isRequired>
                        <FormLabel htmlFor="firstname" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.firstname")}
                        </FormLabel>
                        <Input
                            id="firstname"
                            fontSize="sm"
                            borderRadius="15px"
                            type="text"
                            placeholder={t("register.firstnamePlaceholder")}
                            mb="24px"
                            size="lg"
                            name="firstname"
                            onChange={handleChange}
                            value={formData.firstname}
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                        />
                        <FormLabel htmlFor="name" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.lastname")}
                        </FormLabel>
                        <Input
                            id="lastname"
                            fontSize="sm"
                            borderRadius="15px"
                            type="text"
                            placeholder={t("register.lastnamePlaceholder")}
                            mb="24px"
                            size="lg"
                            name="lastname"
                            onChange={handleChange}
                            value={formData.lastname}
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                        />
                        <FormLabel htmlFor="birthdate" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.birthdate")}
                        </FormLabel>
                        <Input
                            id="birthdate"
                            fontSize="sm"
                            borderRadius="15px"
                            type="date"
                            placeholder={t("register.birthdatePlaceholder")}
                            mb="24px"
                            size="lg"
                            name="birthdate"
                            onChange={handleChange}
                            value={formData.birthdate}
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                        />
                        <FormLabel htmlFor="gender" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.gender")}
                        </FormLabel>
                        <RadioGroup
                            id="gender"
                            fontSize="10px"
                            mb="24px"
                            size="md"
                            name="gender"
                            value={formData.gender}
                            onChange={(value) => setFormData({ ...formData, gender: value })}
                        >
                            <Flex alignItems="center">
                                <Radio
                                    value="Woman"
                                    mr="20px"
                                    borderColor={borderColor}
                                    borderWidth="2px"
                                    _checked={{ borderColor: borderColor, bg: textColor, color: borderColor }}
                                >
                                    <Text fontSize="sm" color={textColor}>{t("register.female")}</Text>
                                </Radio>
                                <Radio
                                    value="Man"
                                    mr="20px"
                                    borderColor={borderColor}
                                    borderWidth="2px"
									_checked={{ borderColor: borderColor, bg: textColor, color: borderColor }}
                                >
                                    <Text fontSize="sm" color={textColor}>{t("register.male")}</Text>
                                </Radio>
                                <Radio
                                    value="Autre"
                                    borderColor={borderColor}
                                    borderWidth="2px"
                                    _checked={{ borderColor: borderColor, bg: textColor, color: borderColor }}
                                >
                                    <Text fontSize="sm" color={textColor}>{t("register.other")}</Text>
                                </Radio>
                            </Flex>
                        </RadioGroup>
                        <FormLabel htmlFor="username" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.username")}
                        </FormLabel>
                        <Input
                            id="username"
                            fontSize="sm"
                            borderRadius="15px"
                            type="text"
                            placeholder={t("register.usernamePlaceholder")}
                            size="lg"
                            mb={usernameError ? "10px" : "24px"}
                            name="username"
                            onChange={handleChange}
                            value={formData.username}
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                        />
                        {usernameError && <Text color="red" mb="10px" fontSize='14px'>{usernameError}</Text>}
                        <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.email")}
                        </FormLabel>
                        <Input
                            id="email"
                            fontSize="sm"
                            borderRadius="15px"
                            type="email"
                            placeholder={t("register.emailPlaceholder")}
                            mb={emailError ? "10px" : "24px"}
                            size="lg"
                            name="email"
                            onChange={handleChange}
                            value={formData.email}
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                        />
                        {emailError && <Text color="red" mb="10px" fontSize='14px'>{emailError}</Text>}
                        <FormLabel htmlFor="password" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.password")}
                        </FormLabel>
                        <InputGroup size="lg">
                            <Input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                fontSize="sm"
                                borderRadius="15px"
                                placeholder={t("register.passwordPlaceholder")}
                                mb={passwordError ? "10px" : "24px"}
                                size="lg"
                                name="password"
                                onChange={handleChange}
                                value={formData.password}
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={togglePasswordVisibility}
                                    bg="none"
                                    _hover={{
                                        bg: 'transparent'
                                    }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {passwordError && <Text color="red" mb="10px" fontSize='14px'>{passwordError}</Text>}
                        <FormLabel htmlFor="password" ms="4px" fontSize="sm" fontWeight="bold" color={textColor}>
                            {t("register.confirmPassword")}
                        </FormLabel>
                        <InputGroup size="lg">
                            <Input
                                id="confirmPassword"
                                fontSize="sm"
                                borderRadius="15px"
                                type={showPassword ? "text" : "password"}
                                placeholder={t("register.confirmPasswordPlaceholder")}
                                mb={confirmPasswordError ? "10px" : "24px"}
                                size="lg"
                                name="confirmPassword"
                                onChange={handleChange}
                                value={formData.confirmPassword}
								_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={togglePasswordVisibility}
                                    bg="none"
                                    _hover={{
                                        bg: 'transparent'
                                    }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {confirmPasswordError && <Text color="red" mb="10px" fontSize='14px'>{confirmPasswordError}</Text>}

						{/* Checkbox for accepting CGU */}
                        <Checkbox
                            name="acceptCGU"
                            isChecked={formData.acceptCGU}
                            onChange={handleChange}
                            colorScheme="teal"
                            mb="24px"
                        >
                            <Text fontSize="sm" color={textColor}>
                                {t("register.acceptCGUText")}{' '}
                                <Link href="/admin/cgu" color="teal.500" isExternal>
                                    {t("register.cguLinkText")}
                                </Link>.
                            </Text>
                        </Checkbox>

                        <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%">
                            <Text color="red" marginBottom="15px" fontWeight="medium">
                                {error}
                            </Text>
                        </Flex>
                        <Button
                            type="submit"
                            fontSize="14px"
                            fontWeight="bold"
                            w="100%"
                            h="45px"
                            mb="24px"
                            borderRadius="10px"
							borderColor={textColorButton}
							textColor={textColorButton}
							bgColor={bgColorButton}
							borderWidth="2px"
							boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
							_hover={{ bg: "gray.700"}}
							_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}
                        >
                            {t("register.signUp")}
                        </Button>
                    </FormControl>
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%">
                        <Text color={textColor} fontWeight="medium">
                            {t("register.alreadyHaveAccount")}
                            <Link color={textColor} ms="5px" href="/admin/signin" fontWeight="bold">
                                {t("register.signIn")}
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
            )}
        </form>
    );
}

export default SignUp;
