import React from 'react';
import {
	Box,
	Flex,
	Text,
	Link,
	useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();
	const textColor = useColorModeValue("gray.500", "gray.200");
	const bg = useColorModeValue('#F9F9F9', 'gray.800');

	return (
		<Box
			as="footer"
			w="100%"
			p="4"
			bg={bg}
			color={textColor}
		>
			<Flex px={{ base: '10px', md: '20px', lg: '40px' }}>
				<Text fontSize="11px" color="red.400" fontWeight="bold" mb={{ base: '10px', md: '0' }} textAlign="justify">
					{t('footer.warning')}
				</Text>
			</Flex>
			<Flex
				mt="30px"
				mb="30px"
				justifyContent={{ base: 'center', md: 'space-between' }}
				alignItems="center"
				flexDirection={{ base: 'column', md: 'row' }}
				px={{ base: '10px', md: '20px', lg: '40px' }}
				textAlign={{ base: 'center', md: 'left' }}
			>
				<Text fontSize="13px" color={textColor} fontWeight="bold" mb={{ base: '10px', md: '0' }}>
					© 2024 Oncopilote.io
				</Text>
				<Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }}>
					<Link fontSize="12px" color={textColor} mr={{ md: '40px' }} mb={{ base: '10px', md: '0' }} href="/admin/privacy-policy">
						{t('footer.privacyPolicy')}
					</Link>
					<Link fontSize="12px" color={textColor} mr={{ md: '40px' }} mb={{ base: '10px', md: '0' }} href="/admin/cookie-policy">
						{t('footer.cookiePolicy')}
					</Link>
					<Link fontSize="12px" color={textColor} href="/admin/data-protection">
						{t('footer.CGU')}
					</Link>
				</Flex>
			</Flex>
		</Box>
	);
}

export default Footer;
